<template>
  <div class="content medical">
    <app-cells>
      <button @click="onShowModal" class="btn-back"></button>
      <h1 class="title">{{ $t('medical.title') }}</h1>
    </app-cells>
    <div class="medical__part">
      <h2 class="title medical__title">1. {{ $t('medical.personal.title') }}</h2>
      <div class="medical__row medical__row--mix-1">
        <app-form-group :label="$t('medical.personal.name')" label-for="first_name">
          <template #additional>
            <app-button
                v-tooltip="{content: $t('contact_your_m'), placement: 'top-start', offset: 10}"
                theme="tooltip">
            </app-button>
          </template>
          <app-input
            v-model="form.first_name"
            id="first_name"
            :placeholder="$t('home.personal.first_name_placeholder')"
            :error="$v.form.first_name.$error"
            @input="onChangeRUToUS($event, 'first_name')"
            name="first_name_m"
            autocomplete="off"
            readonly
          />
          <template #error>
            <div v-if="$v.form.first_name.$dirty && !$v.form.first_name.required">{{ $t('medical.validation.req') }}</div>
          </template>
        </app-form-group>
        <app-form-group :label="$t('medical.personal.surname')" label-for="last_name">
          <template #additional>
            <app-button
                v-tooltip="{content: $t('contact_your_m'), placement: 'top-start', offset: 10}"
                theme="tooltip">
            </app-button>
          </template>
          <app-input
            v-model="form.last_name"
            id="last_name"
            :placeholder="$t('home.personal.last_name_placeholder')"
            :error="$v.form.last_name.$error"
            @input="onChangeRUToUS($event, 'last_name')"
            name="last_name_m"
            autocomplete="off"
            readonly
          />
          <template #error>
            <div v-if="$v.form.last_name.$dirty && !$v.form.last_name.required">{{ $t('medical.validation.req') }}</div>
          </template>
        </app-form-group>
        <app-form-group :label="$t('medical.personal.dob')">
          <date-picker
            v-model="form.dob"
            format="DD MMM YYYY"
            value-type="YYYY-MM-DD"
            :placeholder="$t('home.personal.dob_placeholder')"
            class="datepicker"
            popup-class="datepicker__popup"
            title-format="DD MMM YYYY"
            :lang="getCurrentLang"
            :class="{'datepicker--error': $v.form.dob.$error}"
            name="dob_m"
            autocomplete="off"
          />
          <template #error>
            <div v-if="$v.form.dob.$dirty && !$v.form.dob.required">{{ $t('medical.validation.req') }}</div>
            <div v-if="$v.form.dob.$dirty && !$v.form.dob.minValue">{{ $t('validations.dob_min') }}</div>
            <div v-if="$v.form.dob.$dirty && !$v.form.dob.maxValue">{{ $t('validations.dob_max') }}</div>
          </template>
        </app-form-group>
        <app-form-group :label="$t('medical.personal.gender')" class="form-group--center">
          <app-cells :indent="false">
            <app-radio
              v-for="item in genders_options"
              :key="item.code"
              v-model.number="form.gender"
              :value="item.code"
              name="gender"
            >
              <template #label>{{ item.name }}</template>
            </app-radio>
          </app-cells>
          <template #error>
            <div v-if="$v.form.gender.$dirty && !$v.form.gender.required">{{ $t('medical.validation.req') }}</div>
          </template>
        </app-form-group>
      </div>
      <div class="medical__row medical__row--mix-2">
        <app-form-group :label="$t('medical.personal.citizenship')">
          <v-select
            v-model="form.citizenship"
            :options="countries_options"
            :filterable="true"
            :clearable="false"
            :searchable="true"
            label="name"
            :placeholder="$t('medical.personal.citizenship')"
            class="select"
            :class="{'select--error': $v.form.citizenship.$error}"
          >
            <template #open-indicator>
              <svg xmlns="http://www.w3.org/2000/svg" width="8" height="5" viewBox="0 0 8 5" fill="none">
                <path d="M7.872.418 7.61.138a.423.423 0 0 0-.619 0l-2.99 3.164L1.01.135a.422.422 0 0 0-.619 0L.128.413a.483.483 0 0 0 0 .655L3.691 4.85a.437.437 0 0 0 .31.149h.002a.437.437 0 0 0 .31-.149l3.56-3.773A.482.482 0 0 0 8 .747a.477.477 0 0 0-.128-.33Z" fill="#606060"/>
              </svg>
            </template>
            <template #open-indicator>
              <svg xmlns="http://www.w3.org/2000/svg" width="8" height="5" viewBox="0 0 8 5" fill="none">
                <path d="M7.872.418 7.61.138a.423.423 0 0 0-.619 0l-2.99 3.164L1.01.135a.422.422 0 0 0-.619 0L.128.413a.483.483 0 0 0 0 .655L3.691 4.85a.437.437 0 0 0 .31.149h.002a.437.437 0 0 0 .31-.149l3.56-3.773A.482.482 0 0 0 8 .747a.477.477 0 0 0-.128-.33Z" fill="#606060"/>
              </svg>
            </template>
            <template #option="option">
              <div class="select__item d-center">{{ option.name }}</div>
            </template>
            <template #selected-option="option">
              <div class="selected d-center">{{ option.name }}</div>
            </template>
          </v-select>
          <template #error>
            <div v-if="$v.form.citizenship.$dirty && !$v.form.citizenship.required">{{ $t('medical.validation.req') }}</div>
          </template>
        </app-form-group>
<!--        <app-form-group :label="$t('medical.personal.residence')">-->
<!--          <template #additional>-->
<!--            <app-button-->
<!--              v-tooltip="{content: 'Написать что-то', placement: 'top-start', offset: 10}"-->
<!--              theme="tooltip">-->
<!--            </app-button>-->
<!--          </template>-->
<!--          <v-select-->
<!--            v-model="form.residence"-->
<!--            :reduce="item => item.code"-->
<!--            :options="countries_options"-->
<!--            :filterable="true"-->
<!--            :clearable="false"-->
<!--            :searchable="true"-->
<!--            label="name"-->
<!--            :placeholder="$t('home.personal.residence_placeholder')"-->
<!--            class="select"-->
<!--            :class="{'select&#45;&#45;error': $v.form.residence.$error}"-->
<!--          >-->
<!--            <template #open-indicator>-->
<!--              <svg xmlns="http://www.w3.org/2000/svg" width="8" height="5" viewBox="0 0 8 5" fill="none">-->
<!--                <path d="M7.872.418 7.61.138a.423.423 0 0 0-.619 0l-2.99 3.164L1.01.135a.422.422 0 0 0-.619 0L.128.413a.483.483 0 0 0 0 .655L3.691 4.85a.437.437 0 0 0 .31.149h.002a.437.437 0 0 0 .31-.149l3.56-3.773A.482.482 0 0 0 8 .747a.477.477 0 0 0-.128-.33Z" fill="#606060"/>-->
<!--              </svg>-->
<!--            </template>-->
<!--            <template #open-indicator>-->
<!--              <svg xmlns="http://www.w3.org/2000/svg" width="8" height="5" viewBox="0 0 8 5" fill="none">-->
<!--                <path d="M7.872.418 7.61.138a.423.423 0 0 0-.619 0l-2.99 3.164L1.01.135a.422.422 0 0 0-.619 0L.128.413a.483.483 0 0 0 0 .655L3.691 4.85a.437.437 0 0 0 .31.149h.002a.437.437 0 0 0 .31-.149l3.56-3.773A.482.482 0 0 0 8 .747a.477.477 0 0 0-.128-.33Z" fill="#606060"/>-->
<!--              </svg>-->
<!--            </template>-->
<!--            <template #option="option">-->
<!--              <div class="select__item d-center">{{ option.name }}</div>-->
<!--            </template>-->
<!--            <template #selected-option="option">-->
<!--              <div class="selected d-center">{{ option.name }}</div>-->
<!--            </template>-->
<!--          </v-select>-->
<!--          <template #error>-->
<!--            <div v-if="$v.form.residence.$dirty && !$v.form.residence.required">{{ $t('medical.validation.req') }}</div>-->
<!--          </template>-->
<!--        </app-form-group>-->
        <app-form-group :label="$t('medical.personal.passport')" label-for="passport">
          <app-input
            v-model="form.passport"
            id="passport"
            placeholder="-- ------"
            :error="$v.form.passport.$error"
            name="passport_m"
            autocomplete="off"
          />
          <template #error>
            <div v-if="$v.form.passport.$dirty && !$v.form.passport.required">{{ $t('medical.validation.req') }}</div>
          </template>
        </app-form-group>
        <app-form-group :label="$t('medical.personal.passport-date')">
          <date-picker
            v-model="form.passport_expiration_date"
            format="DD MMM YYYY"
            value-type="YYYY-MM-DD"
            :placeholder="$t('home.personal.pass_exp_placeholder')"
            class="datepicker"
            popup-class="datepicker__popup"
            title-format="DD MMM YYYY"
            :lang="getCurrentLang"
            :class="{'datepicker--error': $v.form.passport_expiration_date.$error}"
            name="passport_expiration_date"
            autocomplete="off"
          />
          <template #error>
            <div v-if="$v.form.passport_expiration_date.$dirty && !$v.form.passport_expiration_date.required">{{ $t('medical.validation.req') }}</div>
            <div v-if="$v.form.passport_expiration_date.$dirty && !$v.form.passport_expiration_date.minValue">
              {{ $t('validations.date_min_today') }}
            </div>
          </template>
        </app-form-group>
      </div>
      <div class="medical__line">
        <app-form-group :label="`${not_metric ? $t('medical.personal.height-ft') : $t('medical.personal.height-cm')}`" label-for="height">
          <app-input
            v-model.number="form.height"
            id="height"
            type="number"
            min="1"
            :error="$v.form.height.$error"
            name="height"
            autocomplete="off"
          />
          <template #error>
            <div v-if="$v.form.height.$dirty && !$v.form.height.integer">{{ $t('medical.validation.integer') }}</div>
            <div v-if="$v.form.height.$dirty && !$v.form.height.required">{{ $t('medical.validation.req') }}</div>
            <div v-if="$v.form.height.$dirty && !$v.form.height.between && !not_metric">{{ $t('medical.validation.height') }}</div>
            <div v-if="$v.form.height.$dirty && !$v.form.height.between && not_metric">{{ $t('medical.validation.height-ft') }}</div>
          </template>
        </app-form-group>
        <app-form-group
          v-if="not_metric && contentLanguage !== 'ru'"
          :label="$t('medical.personal.height-inch')"
          label-for="height_inch"
        >
          <app-input
            v-model.number="form.height_inch"
            id="height_inch"
            type="number"
            min="0"
            :error="$v.form.height_inch.$error"
            name="height_inch"
            autocomplete="off"
          />
          <template #error>
            <div v-if="$v.form.height.$dirty && !$v.form.height_inch.integer">{{ $t('medical.validation.integer') }}</div>
            <div v-if="$v.form.height_inch.$dirty && !$v.form.height_inch.required">{{ $t('medical.validation.req') }}</div>
            <div v-if="$v.form.height_inch.$dirty && !$v.form.height_inch.between">{{ $t('medical.validation.height-inch') }}</div>
          </template>
        </app-form-group>
        <app-form-group
          :label="`${not_metric ? $t('medical.personal.weight-lbs') : $t('medical.personal.weight-kg')}`"
          label-for="weight"
        >
          <app-input
            v-model.number="form.weight"
            id="weight"
            type="number"
            min="1"
            :error="$v.form.weight.$error"
            name="weight"
            autocomplete="off"
          />
          <template #error>
            <div v-if="$v.form.height.$dirty && !$v.form.weight.integer">{{ $t('medical.validation.integer') }}</div>
            <div v-if="$v.form.weight.$dirty && !$v.form.weight.required">{{ $t('medical.validation.req') }}</div>
            <div v-if="$v.form.weight.$dirty && !$v.form.weight.between && !not_metric">{{ $t('medical.validation.weight') }}</div>
            <div v-if="$v.form.weight.$dirty && !$v.form.weight.between && not_metric">{{ $t('medical.validation.weight-ft') }}</div>
          </template>
        </app-form-group>
        <app-form-group
          v-if="contentLanguage !== 'ru'"
          :label="$t('medical.personal.switch')"
          class="form-group--center-switch"
        >
          <app-switcher v-model="not_metric" @change="onSwitchMetric" />
        </app-form-group>
        <div class="medical__bmis">
          <div class="medical__bmi">
            <span>{{ $t('medical.personal.bmi') }} {{ this.bmi }}</span>
            <span>{{ $t('medical.personal.rec') }} 18.5 - 24.9</span>
          </div>
          <div class="medical__bmi">
            <app-button
              v-tooltip="{content: $t('medical.personal.tooltip'), placement: 'top-start', offset: 10}"
              theme="tooltip"
              class="btn--tooltip-big"
            />
          </div>
        </div>
      </div>
      <div class="medical__row medical__row--type-200">
        <app-form-group :label="$t('medical.personal.email')" label-for="email">
          <app-input
            v-model="form.email"
            id="email"
            type="email"
            placeholder="mail@example.com"
            :error="$v.form.email.$error"
            name="email_m"
            autocomplete="off"
          />
          <template #error>
            <div v-if="$v.form.email.$dirty && !$v.form.email.required">{{ $t('medical.validation.req') }}</div>
            <div v-if="$v.form.email.$dirty && !$v.form.email.email">{{ $t('validations.email') }}</div>
          </template>
        </app-form-group>
        <app-form-group :label="$t('medical.personal.phone')">
          <app-phone
            v-model="form.phone"
            placeholder="+7 499 113 28 00"
            @change.native="$v.form.phone.$touch()"
            :error="$v.form.phone.$dirty && (!$v.form.phone.required || (form.phone === 0))"
            name="phone_m"
            autocomplete="off"
          />
          <template #error>
            <div v-if="$v.form.phone.$dirty && !$v.form.phone.required">{{ $t('medical.validation.req') }}</div>
            <div v-if="$v.form.phone.$dirty && (form.phone === 0) && $v.form.phone.required">{{ $t('validations.phone') }}</div>
          </template>
        </app-form-group>
        <app-form-group :label="$t('medical.personal.other')" class="form-group--center">
          <app-cells :indent="false">
            <app-checkbox v-model="form.phone_telegram" label="Telegram" :readonly="!form.phone" />
            <app-checkbox v-model="form.phone_whatsapp" label="Whatsapp" :readonly="!form.phone" />
          </app-cells>
        </app-form-group>
      </div>
      <app-form-group>
        <app-cropper-web-cam
          v-model="form.crop"
          :aspectRatio="3/4"
          ref="cropper"
        />
      </app-form-group>
      <h2 class="title medical__title">{{ $t('medical.personal.emergency-title') }}</h2>
      <div class="medical__row medical__row--type-200">
        <app-form-group :label="$t('medical.personal.emergency-name')" label-for="emergency_full_name">
          <app-input
            v-model="form.emergency_contact_fullname"
            id="emergency_full_name"
            :placeholder="$t('medical.personal.emergency-name-placeholder')"
            :error="$v.form.emergency_contact_fullname.$error"
            name="emergency_contact_fullname"
            autocomplete="off"
          />
          <template #error>
            <div v-if="$v.form.emergency_contact_fullname.$dirty && !$v.form.emergency_contact_fullname.required">{{ $t('medical.validation.req') }}</div>
          </template>
        </app-form-group>
        <app-form-group :label="$t('home.personal.emergency_phone')">
          <app-phone
            v-model="form.emergency_contact_phone"
            placeholder="+7 499 113 28 00"
            @change.native="$v.form.emergency_contact_phone.$touch()"
            :error="$v.form.emergency_contact_phone.$dirty && (!$v.form.emergency_contact_phone.required || (form.emergency_contact_phone === 0))"
            name="emergency_contact_phone"
            autocomplete="off"
          />
          <template #error>
            <div v-if="$v.form.emergency_contact_phone.$dirty && !$v.form.emergency_contact_phone.required">{{ $t('medical.validation.req') }}</div>
            <div v-if="$v.form.emergency_contact_phone.$dirty && (form.emergency_contact_phone === 0) && $v.form.emergency_contact_phone.required">{{ $t('validations.phone') }}</div>
          </template>
        </app-form-group>
        <app-form-group :label="$t('medical.personal.other')" class="form-group--center">
          <app-cells :indent="false">
            <app-checkbox
              v-model="form.emergency_contact_phone_telegram"
              label="Telegram"
              :readonly="!form.emergency_contact_phone"
            />
            <app-checkbox
              v-model="form.emergency_contact_phone_whatsapp"
              label="Whatsapp"
              :readonly="!form.emergency_contact_phone"
            />
          </app-cells>
        </app-form-group>
      </div>
    </div>
    <div class="medical__part">
      <h2 class="title medical__title">2. {{ $t('medical.dietary.title') }}</h2>
      <div class="medical__row medical__row--diet">
        <app-form-group :label="$t('medical.dietary.food')">
          <v-select
            v-model="form.food_preference"
            :options="food_options"
            :filterable="false"
            :clearable="false"
            :searchable="false"
            label="name"
            :placeholder="$t('medical.dietary.food')"
            class="select"
            :class="{'select--error': $v.form.food_preference.$error}"
          >
            <template #open-indicator>
              <svg xmlns="http://www.w3.org/2000/svg" width="8" height="5" viewBox="0 0 8 5" fill="none">
                <path d="M7.872.418 7.61.138a.423.423 0 0 0-.619 0l-2.99 3.164L1.01.135a.422.422 0 0 0-.619 0L.128.413a.483.483 0 0 0 0 .655L3.691 4.85a.437.437 0 0 0 .31.149h.002a.437.437 0 0 0 .31-.149l3.56-3.773A.482.482 0 0 0 8 .747a.477.477 0 0 0-.128-.33Z" fill="#606060"/>
              </svg>
            </template>
            <template #open-indicator>
              <svg xmlns="http://www.w3.org/2000/svg" width="8" height="5" viewBox="0 0 8 5" fill="none">
                <path d="M7.872.418 7.61.138a.423.423 0 0 0-.619 0l-2.99 3.164L1.01.135a.422.422 0 0 0-.619 0L.128.413a.483.483 0 0 0 0 .655L3.691 4.85a.437.437 0 0 0 .31.149h.002a.437.437 0 0 0 .31-.149l3.56-3.773A.482.482 0 0 0 8 .747a.477.477 0 0 0-.128-.33Z" fill="#606060"/>
              </svg>
            </template>
            <template #option="option">
              <div class="select__item d-center">{{ option.name }}</div>
            </template>
            <template #selected-option="option">
              <div class="selected d-center">{{ option.name }}</div>
            </template>
          </v-select>
          <template #error>
            <div v-if="$v.form.food_preference.$dirty && !$v.form.food_preference.required">{{ $t('medical.validation.req') }}</div>
          </template>
        </app-form-group>
        <div class="medical__diet-switchers">
          <app-form-group :label="$t('home.personal.allergies_label')" class="form-group--center-switch">
            <app-switcher v-model="allergies_switcher" />
          </app-form-group>
          <app-form-group :label="$t('home.personal.lactose')" class="form-group--center-switch">
            <app-switcher v-model="form.food_lactose_free" />
          </app-form-group>
          <app-form-group :label="$t('home.personal.gluten')" class="form-group--center-switch">
            <app-switcher v-model="form.food_gluten_free" />
          </app-form-group>
        </div>
        <div v-if="form.food_preference" class="medical__diet-switchers">
          <app-form-group :label="$t('home.personal.food_halal')" class="form-group--center-switch">
            <app-switcher v-model="form.food_halal" />
          </app-form-group>
          <app-form-group :label="$t('home.personal.food_no_red_meat')" class="form-group--center-switch">
            <app-switcher v-model="form.food_no_red_meat" />
          </app-form-group>
          <app-form-group :label="$t('home.personal.food_no_chicken')" class="form-group--center-switch">
            <app-switcher v-model="form.food_no_chicken" />
          </app-form-group>
        </div>
      </div>
      <div v-if="allergies_switcher" class="medical__row medical__row--mix-6">
        <app-form-group v-if="allergies_switcher" :label="$t('medical.dietary.allergies')" label-for="food">
          <app-input
            v-model="form.food_allergy"
            id="food"
            :placeholder="$t('medical.dietary.details')"
            name="food_allergy_m"
            autocomplete="off"
          />
        </app-form-group>
      </div>
    </div>
<!--    <div class="medical__part">-->
<!--      <h2 class="title medical__title">3. {{ $t('medical.insurance.title') }}</h2>-->
<!--      <div class="medical__row medical__row&#45;&#45;mix-4">-->
<!--        <app-form-group :label="$t('medical.insurance.company')" label-for="company">-->
<!--          <app-input-->
<!--            v-model="form.climbing_insurance_company"-->
<!--            id="company"-->
<!--            :placeholder="$t('medical.insurance.company')"-->
<!--            :error="$v.form.climbing_insurance_company.$error"-->
<!--            name="climbing_insurance_company_m"-->
<!--            autocomplete="off"-->
<!--          />-->
<!--          <template #error>-->
<!--            <div v-if="$v.form.climbing_insurance_company.$dirty && !$v.form.climbing_insurance_company.required">{{ $t('medical.validation.req') }}</div>-->
<!--          </template>-->
<!--        </app-form-group>-->
<!--        <app-form-group :label="$t('medical.insurance.number')" label-for="number">-->
<!--          <app-input-->
<!--            v-model="form.climbing_insurance_number"-->
<!--            id="number"-->
<!--            placeholder="&#45;&#45; &#45;&#45;&#45;&#45;&#45;&#45;"-->
<!--            :error="$v.form.climbing_insurance_number.$error"-->
<!--            name="climbing_insurance_number_m"-->
<!--            autocomplete="off"-->
<!--          />-->
<!--          <template #error>-->
<!--            <div v-if="$v.form.climbing_insurance_number.$dirty && !$v.form.climbing_insurance_number.required">{{ $t('medical.validation.req') }}</div>-->
<!--          </template>-->
<!--        </app-form-group>-->
<!--        <app-form-group :label="$t('medical.insurance.start')">-->
<!--          <date-picker-->
<!--            v-model="form.climbing_insurance_coverage_start"-->
<!--            format="DD MMM YYYY"-->
<!--            value-type="YYYY-MM-DD"-->
<!--            :placeholder="$t('home.personal.valid_from_placeholder')"-->
<!--            class="datepicker"-->
<!--            popup-class="datepicker__popup"-->
<!--            title-format="DD MMM YYYY"-->
<!--            :lang="getCurrentLang"-->
<!--            :class="{'datepicker&#45;&#45;error': $v.form.climbing_insurance_coverage_start.$error}"-->
<!--            name="climbing_insurance_coverage_start_m"-->
<!--            autocomplete="off"-->
<!--          />-->
<!--          <template #error>-->
<!--            <div v-if="$v.form.climbing_insurance_coverage_start.$dirty && !$v.form.climbing_insurance_coverage_start.required">{{ $t('medical.validation.req') }}</div>-->
<!--            <div v-if="$v.form.climbing_insurance_coverage_start.$dirty && !$v.form.climbing_insurance_coverage_start.maxValue">-->
<!--              {{ $t('validations.start_max_end') }}-->
<!--            </div>-->
<!--          </template>-->
<!--        </app-form-group>-->
<!--        <app-form-group :label="$t('medical.insurance.end')">-->
<!--          <date-picker-->
<!--            v-model="form.climbing_insurance_coverage_end"-->
<!--            format="DD MMM YYYY"-->
<!--            value-type="YYYY-MM-DD"-->
<!--            :placeholder="$t('home.personal.valid_until_placeholder')"-->
<!--            class="datepicker"-->
<!--            popup-class="datepicker__popup"-->
<!--            title-format="DD MMM YYYY"-->
<!--            :lang="getCurrentLang"-->
<!--            :class="{'datepicker&#45;&#45;error': $v.form.climbing_insurance_coverage_end.$error}"-->
<!--            name="climbing_insurance_coverage_end_m"-->
<!--            autocomplete="off"-->
<!--          />-->
<!--          <template #error>-->
<!--            <div v-if="$v.form.climbing_insurance_coverage_end.$dirty && !$v.form.climbing_insurance_coverage_end.required">{{ $t('medical.validation.req') }}</div>-->
<!--            <div v-if="$v.form.climbing_insurance_coverage_end.$dirty && !$v.form.climbing_insurance_coverage_end.minValue">-->
<!--              {{ $t('validations.date_min_today') }}-->
<!--            </div>-->
<!--          </template>-->
<!--        </app-form-group>-->
<!--        <app-form-group :label="$t('home.personal.emer_phone')">-->
<!--          <app-phone-->
<!--            v-model="form.climbing_insurance_emergency_phone"-->
<!--            placeholder="+7 499 113 28 00"-->
<!--            @change.native="$v.form.climbing_insurance_emergency_phone.$touch()"-->
<!--            :error="$v.form.climbing_insurance_emergency_phone.$dirty && (!$v.form.climbing_insurance_emergency_phone.required || (form.climbing_insurance_emergency_phone === 0))"-->
<!--            name="climbing_insurance_emergency_phone_m"-->
<!--            autocomplete="off"-->
<!--          />-->
<!--          <template #error>-->
<!--            <div v-if="$v.form.climbing_insurance_emergency_phone.$dirty && !$v.form.climbing_insurance_emergency_phone.required">{{ $t('medical.validation.req') }}</div>-->
<!--            <div v-if="$v.form.climbing_insurance_emergency_phone.$dirty && (form.climbing_insurance_emergency_phone === 0) && $v.form.climbing_insurance_emergency_phone.required">{{ $t('validations.phone') }}</div>-->
<!--          </template>-->
<!--        </app-form-group>-->
<!--      </div>-->
<!--      <p class="medical__note">{{ $t('medical.insurance.note') }} <span>{{ $t('medical.insurance.text') }}</span></p>-->
<!--    </div>-->
    <section class="medical__section">
      <h2 class="title medical__title">3. {{ $t('medical.risk.title') }}</h2>
      <p>{{ $t('medical.risk.paragraph1') }}</p>
      <div class="medical__spoiler" :class="{'medical__spoiler--show': show_spoiler}">
        <b>{{ $t('medical.risk.b1') }}</b>
        <p v-if="branding === 'false'">{{ $t('medical.risk.paragraph2') }}</p>
        <p v-if="branding === 'true'">{{ $t('medical.risk.paragraph2_branding') }}</p>
        <p v-if="branding === 'false'">{{ $t('medical.risk.paragraph3') }}</p>
        <p v-if="branding === 'true'">{{ $t('medical.risk.paragraph3_branding') }}</p>
        <b>{{ $t('medical.risk.b2') }}</b>
        <p>{{ $t('medical.risk.paragraph4') }}</p>
        <p>{{ $t('medical.risk.paragraph5') }}</p>
        <p>{{ $t('medical.risk.paragraph6') }}</p>
        <b>{{ $t('medical.risk.b3') }}</b>
        <p>{{ $t('medical.risk.paragraph7') }}</p>
        <p>{{ $t('medical.risk.paragraph8') }}</p>
        <b>{{ $t('medical.risk.b2') }}</b>
        <p v-if="branding === 'false'">{{ $t('medical.risk.paragraph9') }}</p>
        <p v-if="branding === 'true'">{{ $t('medical.risk.paragraph9_branding') }}</p>
        <p>{{ $t('medical.risk.paragraph10') }}</p>
        <b>{{ $t('medical.risk.b4') }}</b>
        <p>{{ $t('medical.risk.paragraph11') }}</p>
        <b>{{ $t('medical.risk.b2') }}</b>
        <p>{{ $t('medical.risk.paragraph12') }}</p>
      </div>
      <div class="medical__holder">
        <button
          @click="show_spoiler = !show_spoiler"
          class="medical__spoiler-control"
          :class="{'medical__spoiler-control--show': show_spoiler}"
        >
          <img src="@/assets/img/arrow-spoiler.svg" alt="arrow">
          <span v-if="show_spoiler">{{ $t('medical.risk.spoiler-btn-hide') }}</span>
          <span v-else>{{ $t('medical.risk.spoiler-btn-show') }}</span>
        </button>
      </div>
    </section>
    <section class="medical__section">
      <h2 class="title medical__title">4. {{ $t('medical.clearance.title') }}</h2>
      <p>{{ $t('medical.clearance.paragraph') }}</p>
      <ul>
        <li>{{ $t('medical.clearance.li1') }}</li>
        <li>{{ $t('medical.clearance.li2') }}</li>
        <li>{{ $t('medical.clearance.li3') }}</li>
        <li v-if="branding === 'false'">{{ $t('medical.clearance.li4') }}</li>
        <li v-if="branding === 'true'">{{ $t('medical.clearance.li4_branding') }}</li>
      </ul>
    </section>
    <section class="medical__section">
      <h2 v-if="branding === 'false'" class="title medical__title">5. {{ $t('medical.conditions.title') }}</h2>
      <h2 v-if="branding === 'true'" class="title medical__title">5. {{ $t('medical.conditions.title_branding') }}</h2>
      <p v-if="branding === 'false'">{{ $t('medical.conditions.p1') }}</p>
      <p v-if="branding === 'true'">{{ $t('medical.conditions.p1_branding') }}</p>
      <p>{{ $t('medical.conditions.p2') }}</p>
      <ul>
        <li>{{ $t('medical.conditions.li1') }}</li>
        <li class="no-indent">
          {{ $t('medical.conditions.li2') }}
          <ul>
            <li>{{ $t('medical.conditions.li2-1') }}</li>
            <li>{{ $t('medical.conditions.li2-2') }}</li>
            <li>{{ $t('medical.conditions.li2-3') }}</li>
            <li>{{ $t('medical.conditions.li2-4') }}</li>
          </ul>
        </li>
        <li>{{ $t('medical.conditions.li3') }}</li>
        <li>{{ $t('medical.conditions.li4') }}</li>
      </ul>
      <p>{{ $t('medical.conditions.p3') }}</p>
      <ul>
        <li>{{ $t('medical.conditions.li5') }}</li>
        <li>{{ $t('medical.conditions.li6') }}</li>
        <li>{{ $t('medical.conditions.li7') }}</li>
        <li>{{ $t('medical.conditions.li8') }}</li>
        <li>{{ $t('medical.conditions.li9') }}</li>
        <li>{{ $t('medical.conditions.li10') }}</li>
        <li>{{ $t('medical.conditions.li11') }}</li>
        <li>{{ $t('medical.conditions.li12') }}</li>
        <li>{{ $t('medical.conditions.li13') }}</li>
        <li>{{ $t('medical.conditions.li14') }}</li>
      </ul>
      <p>{{ $t('medical.conditions.p4') }}</p>
    </section>
    <section class="medical__section medical__section--indent">
      <h2 class="title medical__title">6. {{ $t('medical.privacy.title') }}</h2>
      <p>{{ $t('medical.privacy.p1') }}</p>
      <!--<p>{{ $t('medical.privacy.p2') }} <a :href="$t('medical.privacy.link')" target="_blank">{{ $t('medical.privacy.link-text') }}</a></p>-->
    </section>
    <h2 class="title medical__title">7. {{ $t('medical.health.title') }}</h2>
    <p class="medical__paragraph">{{ $t('medical.health.p1') }}</p>
    <div class="medical__questions">
      <div class="medical__question">
        <div class="medical__question-block">
          <span>{{ $t('medical.health.q1') }}</span>
          <span
            v-if="$v.show_details_smoking.$dirty && !$v.show_details_smoking.required"
            class="medical__question-error"
          >
            {{ $t('medical.validation.req') }}
          </span>
        </div>
        <div class="medical__radios">
          <label class="medical__radio">
            {{ $t('medical.health.yes') }}
            <input type="radio" name="smoking" v-model="show_details_smoking" :value="true">
            <span class="medical__radio-bg"></span>
          </label>
          <label class="medical__radio">
            {{ $t('medical.health.no') }}
            <input type="radio" name="smoking" v-model="show_details_smoking" :value="false">
            <span class="medical__radio-bg"></span>
          </label>
        </div>
        <div class="medical__details" :class="{'medical__details--show': show_details_smoking}">
          <app-form-group class="form-group--no-indent form-group--error-indent">
            <v-select
              v-model="form.health_smoking"
              :options="smoking_options"
              :filterable="false"
              :clearable="false"
              :searchable="false"
              label="name"
              :placeholder="$t('medical.health.select-placeholder')"
              class="select"
              :class="{'select--error': $v.form.health_smoking.$error}"
            >
              <template #open-indicator>
                <svg xmlns="http://www.w3.org/2000/svg" width="8" height="5" viewBox="0 0 8 5" fill="none">
                  <path
                    d="M7.872.418 7.61.138a.423.423 0 0 0-.619 0l-2.99 3.164L1.01.135a.422.422 0 0 0-.619 0L.128.413a.483.483 0 0 0 0 .655L3.691 4.85a.437.437 0 0 0 .31.149h.002a.437.437 0 0 0 .31-.149l3.56-3.773A.482.482 0 0 0 8 .747a.477.477 0 0 0-.128-.33Z"
                    fill="#606060" />
                </svg>
              </template>
              <template #option="option">
                <div class="select__item d-center">{{ option.name }}</div>
              </template>
              <template #selected-option="option">
                <div class="selected d-center">{{ option.name }}</div>
              </template>
            </v-select>
            <template #error>
              <div v-if="$v.form.health_smoking.$dirty && !$v.form.health_smoking.required">{{ $t('medical.validation.req') }}</div>
            </template>
          </app-form-group>
        </div>
      </div>
      <div class="medical__question">
        <div class="medical__question-block medical__question-block--flex">
          <div>
            <span>{{ $t('medical.health.q11') }}</span>
            <span
              v-if="$v.show_details_diabetes.$dirty && !$v.show_details_diabetes.required"
              class="medical__question-error"
            >
            {{ $t('medical.validation.req') }}
          </span>
          </div>
<!--          <app-button-->
<!--            v-tooltip="{content: 'Написать что-то', placement: 'top-start', offset: 10}"-->
<!--            theme="tooltip"-->
<!--          />-->
        </div>
        <div class="medical__radios">
          <label class="medical__radio">
            {{ $t('medical.health.yes') }}
            <input type="radio" name="details_diabetes" v-model="show_details_diabetes" :value="true">
            <span class="medical__radio-bg"></span>
          </label>
          <label class="medical__radio">
            {{ $t('medical.health.no') }}
            <input type="radio" name="details_diabetes" v-model="show_details_diabetes" :value="false">
            <span class="medical__radio-bg"></span>
          </label>
        </div>
        <div class="medical__details" :class="{'medical__details--show': show_details_diabetes}">
          <app-form-group class="form-group--no-indent form-group--error-indent">
            <v-select
              v-model="form.health_diabetes"
              :options="diabetes_options"
              :filterable="false"
              :clearable="false"
              :searchable="false"
              label="name"
              :placeholder="$t('medical.health.select-placeholder')"
              class="select"
              :class="{'select--error': $v.form.health_diabetes.$error}"
            >
              <template #open-indicator>
                <svg xmlns="http://www.w3.org/2000/svg" width="8" height="5" viewBox="0 0 8 5" fill="none">
                  <path
                    d="M7.872.418 7.61.138a.423.423 0 0 0-.619 0l-2.99 3.164L1.01.135a.422.422 0 0 0-.619 0L.128.413a.483.483 0 0 0 0 .655L3.691 4.85a.437.437 0 0 0 .31.149h.002a.437.437 0 0 0 .31-.149l3.56-3.773A.482.482 0 0 0 8 .747a.477.477 0 0 0-.128-.33Z"
                    fill="#606060" />
                </svg>
              </template>
              <template #option="option">
                <div class="select__item d-center">{{ option.name }}</div>
              </template>
              <template #selected-option="option">
                <div class="selected d-center">{{ option.name }}</div>
              </template>
            </v-select>
            <template #error>
              <div v-if="$v.form.health_diabetes.$dirty && !$v.form.health_diabetes.required">{{ $t('medical.validation.req') }}</div>
            </template>
          </app-form-group>
        </div>
      </div>
      <div
        v-for="(item, index) of health_fields"
        :key="item.field"
        class="medical__question"
      >
        <div class="medical__question-block">
          <span>{{ item.label }}</span>
          <span
            v-if="$v.health_fields.$each[index].show_details.$dirty && !$v.health_fields.$each[index].show_details.required"
            class="medical__question-error"
          >
            {{ $t('medical.validation.req') }}
          </span>
        </div>
        <div class="medical__radios">
          <label class="medical__radio">
            {{ $t('medical.health.yes') }}
            <input type="radio" :name="item.field" v-model="item.show_details" :value="true">
            <span class="medical__radio-bg"></span>
          </label>
          <label class="medical__radio">
            {{ $t('medical.health.no') }}
            <input type="radio" :name="item.field" v-model="item.show_details" :value="false">
            <span class="medical__radio-bg"></span>
          </label>
        </div>
        <div class="medical__details" :class="{'medical__details--show': item.show_details}">
          <app-form-group class="form-group--no-indent">
            <app-input
              v-model="form.health[item.field]"
              placeholder="Details"
              :error="$v.form.health[item.field].$error"
            />
            <template #error>
              <div v-if="$v.form.health[item.field].$dirty && !$v.form.health[item.field].required">{{ $t('medical.validation.req') }}</div>
            </template>
          </app-form-group>
        </div>
      </div>
      <div class="medical__question">
        <div class="medical__question-block">
          <div>
            <span>{{ $t('medical.vaccine.label-allergic') }}</span>
            <span
              v-if="$v.show_details_allergic.$dirty && !$v.show_details_allergic.required"
              class="medical__question-error"
            >
            {{ $t('medical.validation.req') }}
          </span>
          </div>
        </div>
        <div class="medical__radios">
          <label class="medical__radio">
            {{ $t('medical.health.yes') }}
            <input type="radio" name="details_allergic" v-model="show_details_allergic" :value="true">
            <span class="medical__radio-bg"></span>
          </label>
          <label class="medical__radio">
            {{ $t('medical.health.no') }}
            <input type="radio" name="details_allergic" v-model="show_details_allergic" :value="false">
            <span class="medical__radio-bg"></span>
          </label>
        </div>
        <div class="medical__details" :class="{'medical__details--show': show_details_allergic}">
          <app-form-group class="form-group--no-indent form-group--error-indent">
            <app-input
              v-model="form.allergic_medications"
              placeholder="Details"
              :error="$v.form.allergic_medications.$error"
            />
            <template #error>
              <div v-if="$v.form.allergic_medications.$dirty && !$v.form.allergic_medications.required">{{ $t('medical.validation.req') }}</div>
            </template>
          </app-form-group>
        </div>
      </div>
    </div>
    <div class="medical__borders">
      <div class="medical__borders-item">
        <div class="medical__subt">
          <img src="@/assets/img/healthcare.svg" alt="General">
          {{ $t('medical.progress.title1') }}:
        </div>
        <div class="medical__range">
          <label
            class="medical__range-label medical__range-label--first"
            :class="{'medical__range-label--prev': [2, 3, 4].includes(form.asses_general_health)}"
          >
            <input v-model.number="form.asses_general_health" type="radio" name="health" value="1">
            <span class="medical__range-name">{{ $t('medical.progress.poor') }}</span>
            <span class="medical__range-point"></span>
          </label>
          <label
            class="medical__range-label medical__range-label--second"
            :class="{'medical__range-label--prev': [3, 4].includes(form.asses_general_health)}"
          >
            <input v-model.number="form.asses_general_health" type="radio" name="health" value="2">
            <span class="medical__range-name">{{ $t('medical.progress.fair') }}</span>
            <span class="medical__range-point"></span>
            <span class="medical__range-line"></span>
          </label>
          <label
            class="medical__range-label medical__range-label--third"
            :class="{'medical__range-label--prev': form.asses_general_health === 4}"
          >
            <input v-model.number="form.asses_general_health" type="radio" name="health" value="3">
            <span class="medical__range-name">{{ $t('medical.progress.good') }}</span>
            <span class="medical__range-point"></span>
            <span class="medical__range-line"></span>
          </label>
          <label class="medical__range-label medical__range-label--fourth">
            <input v-model.number="form.asses_general_health" type="radio" name="health" value="4">
            <span class="medical__range-name">{{ $t('medical.progress.excellent') }}</span>
            <span class="medical__range-point"></span>
            <span class="medical__range-line"></span>
          </label>
        </div>
        <div
          v-if="$v.form.asses_general_health.$dirty && !$v.form.asses_general_health.required"
          class="medical__borders-error"
        >
          {{ $t('validations.required') }}
        </div>
      </div>
      <div class="medical__borders-item">
        <div class="medical__subt">
          <img src="@/assets/img/cardiogram.svg" alt="General">
          {{ $t('medical.progress.title2') }}:
        </div>
        <div class="medical__range">
          <label
            class="medical__range-label medical__range-label--first"
            :class="{'medical__range-label--prev': [2, 3, 4].includes(form.asses_stamina)}"
          >
            <input v-model.number="form.asses_stamina" type="radio" name="stamina" value="1">
            <span class="medical__range-name">{{ $t('medical.progress.poor') }}</span>
            <span class="medical__range-point"></span>
          </label>
          <label
            class="medical__range-label medical__range-label--second"
            :class="{'medical__range-label--prev': [3, 4].includes(form.asses_stamina)}"
          >
            <input v-model.number="form.asses_stamina" type="radio" name="stamina" value="2">
            <span class="medical__range-name">{{ $t('medical.progress.fair') }}</span>
            <span class="medical__range-point"></span>
            <span class="medical__range-line"></span>
          </label>
          <label
            class="medical__range-label medical__range-label--third"
            :class="{'medical__range-label--prev': form.asses_stamina === 4}"
          >
            <input v-model.number="form.asses_stamina" type="radio" name="stamina" value="3">
            <span class="medical__range-name">{{ $t('medical.progress.good') }}</span>
            <span class="medical__range-point"></span>
            <span class="medical__range-line"></span>
          </label>
          <label class="medical__range-label medical__range-label--fourth">
            <input v-model.number="form.asses_stamina" type="radio" name="stamina" value="4">
            <span class="medical__range-name">{{ $t('medical.progress.excellent') }}</span>
            <span class="medical__range-point"></span>
            <span class="medical__range-line"></span>
          </label>
        </div>
        <div
          v-if="$v.form.asses_stamina.$dirty && !$v.form.asses_stamina.required"
          class="medical__borders-error"
        >
          {{ $t('validations.required') }}
        </div>
      </div>
    </div>
    <div class="medical__medications">
      <div class="medical__subt">
        <img src="@/assets/img/pill.svg" alt="Pill">
        {{ $t('medical.medications.title') }}
      </div>
      <p class="medical__paragraph">{{ $t('medical.medications.p') }}</p>
      <div
        v-for="(item, index) of form.prescription_medications"
        :key="index"
        class="medical__medication"
      >
        <app-form-group class="form-group--no-indent" :label="$t('medical.medications.label1')">
          <app-input
            v-model="item.name"
            :placeholder="$t('medical.medications.label1')"
            @change="$v.form.prescription_medications.$each[index].name.$touch()"
            :error="$v.form.prescription_medications.$each[index].name.$error"
          />
          <template #error>
            <div v-if="$v.form.prescription_medications.$each[index].name.$dirty && !$v.form.prescription_medications.$each[index].name.required">{{ $t('medical.validation.req') }}</div>
          </template>
        </app-form-group>
        <app-form-group class="form-group--no-indent" :label="$t('medical.medications.label2')">
          <app-input
            v-model="item.dose"
            :placeholder="$t('medical.medications.label2')"
            @change="$v.form.prescription_medications.$each[index].dose.$touch()"
            :error="$v.form.prescription_medications.$each[index].dose.$error"
          />
          <template #error>
            <div v-if="$v.form.prescription_medications.$each[index].dose.$dirty && !$v.form.prescription_medications.$each[index].dose.required">{{ $t('medical.validation.req') }}</div>
          </template>
        </app-form-group>
        <app-form-group class="form-group--no-indent" :label="$t('medical.medications.label3')">
          <app-input
            v-model="item.frequency"
            :placeholder="$t('medical.medications.label3')"
            @change="$v.form.prescription_medications.$each[index].frequency.$touch()"
            :error="$v.form.prescription_medications.$each[index].frequency.$error"
          />
          <template #error>
            <div v-if="$v.form.prescription_medications.$each[index].frequency.$dirty && !$v.form.prescription_medications.$each[index].frequency.required">{{ $t('medical.validation.req') }}</div>
          </template>
        </app-form-group>
        <app-form-group class="form-group--no-indent" :label="$t('medical.medications.label4')">
          <app-input
            v-model="item.purpose"
            :placeholder="$t('medical.medications.label4')"
            @change="$v.form.prescription_medications.$each[index].purpose.$touch()"
            :error="$v.form.prescription_medications.$each[index].purpose.$error"
          />
          <template #error>
            <div v-if="$v.form.prescription_medications.$each[index].purpose.$dirty && !$v.form.prescription_medications.$each[index].purpose.required">{{ $t('medical.validation.req') }}</div>
          </template>
        </app-form-group>
        <button @click="onRemoveItem(index)" class="medical__medication-delete">
          <img src="@/assets/img/delete-icon.svg" alt="delete">
        </button>
      </div>
      <button @click="onCloneItem" class="medical__spoiler-control">
        <img src="@/assets/img/add-icon.svg" alt="add">
        <span>{{ $t('medical.medications.btn') }}</span>
      </button>
    </div>
    <div class="medical__medications medical__medications--no-indent">
      <div class="medical__subt">
        <img src="@/assets/img/mosquito.svg" alt="Mosquito">
        {{ $t('medical.malaria.title') }}
      </div>
      <div class="medical__radios medical__radios--indent">
        <label class="medical__radio">
          {{ $t('medical.health.yes') }}
          <input type="radio" name="malaria" v-model="form.anti_malaria_pills" :value="true">
          <span class="medical__radio-bg"></span>
        </label>
        <label class="medical__radio">
          {{ $t('medical.health.no') }}
          <input type="radio" name="malaria" v-model="form.anti_malaria_pills" :value="false">
          <span class="medical__radio-bg"></span>
        </label>
      </div>
      <div v-if="$v.form.anti_malaria_pills.$error" class="medical__error">{{ $t('medical.validation.req') }}</div>
      <div v-if="form.anti_malaria_pills === true" class="medical__info">
        <img src="@/assets/img/warning-icon.svg" alt="warning">
        <p>{{ $t('medical.malaria.text') }}</p>
      </div>
    </div>
<!--    <app-form-group :label="$t('medical.vaccine.label-allergic')" label-for="allergic">-->
<!--      <app-input-->
<!--        v-model="form.allergic_medications"-->
<!--        id="allergic"-->
<!--        :placeholder="$t('medical.vaccine.placeholder')"-->
<!--      />-->
<!--    </app-form-group>-->
    <app-form-group :label="$t('medical.vaccine.label-expeditions')" label-for="expeditions">
      <app-textarea
        v-model="form.mountain_experience"
        id="expeditions"
        :placeholder="$t('medical.vaccine.placeholder')"
      />
    </app-form-group>
    <p v-if="branding === 'false'" class="medical__paragraph">{{ $t('medical.dont-print') }}</p>
    <p v-if="branding === 'true'" class="medical__paragraph">{{ $t('medical.dont-print_branding') }}</p>
    <p v-if="branding === 'false'" class="medical__paragraph">{{ $t('medical.dont-print2') }}</p>
    <label class="medical__info" :class="{'medical__info--error': $v.form.confirmation.$error}">
      <app-checkbox v-model="form.confirmation" />
      <span>{{ $t('medical.confirm') }}</span>
    </label>
    <p class="medical__paragraph">{{ $t('medical.contact-us') }}</p>
    <div class="medical__footer">
      <app-button @click.native="onShowModal" theme="dark">
        <img src="@/assets/img/arrow-back.svg" alt="back">
        <span>{{ $t('medical.btn-back') }}</span>
      </app-button>
      <app-button @click.native="onCheckForm" ref="submit">{{ $t('medical.btn-submit') }}</app-button>
    </div>
    <modal
      name="confirm-modal"
      :width="400"
      :height="'auto'"
      :scrollable="true"
      :adaptive="true"
    >
      <div class="modal modal--confirm">
        <div class="modal__top">
          <img src="@/assets/img/warning-modal-icon.svg" alt="warning">
          <span>{{ $t('modal-back.title') }}</span>
        </div>
        <div class="modal__text">{{ $t('modal-back.text') }}</div>
        <app-cells position="center" :indent="false">
          <app-button @click.native="onHideModal" theme="dark">{{ $t('modal-back.stay') }}</app-button>
          <app-button @click.native="onRouteHome">{{ $t('modal-back.leave') }}</app-button>
        </app-cells>
      </div>
    </modal>
  </div>
</template>

<script>
import {
  getCountries,
  getFoodPreferences,
  getGenders,
  getMedicalForm,
  getSmokingFrequencies,
  getDiabetesTypes,
  patchMedicalForm
} from '@/http'
import { required, email, requiredIf, between, integer } from 'vuelidate/lib/validators'
import 'vue2-datepicker/locale/ru'
import 'vue2-datepicker/locale/en'

export default {
  name: 'MedicalForm',
  data() {
    return {
      show_spoiler: false,
      form: {
        health: {},
        anti_malaria_pills: null,
        height: null,
        weight: null,
        height_inch: null,
        prescription_medications: [],
      },
      not_metric: false,
      show_details_smoking: null,
      show_details_diabetes: null,
      show_details_allergic: null,
      health_fields: [
        {
          label: this.$t('medical.health.q2'),
          show_details: null,
          field: 'high_blood_pressure',
        },
        {
          label: this.$t('medical.health.q3'),
          show_details: null,
          field: 'epileptic_seizure',
        },
        {
          label: this.$t('medical.health.q4'),
          show_details: null,
          field: 'fainting',
        },
        {
          label: this.$t('medical.health.q5'),
          show_details: null,
          field: 'heart_diseases',
        },
        {
          label: this.$t('medical.health.q6'),
          show_details: null,
          field: 'mental_conditions',
        },
        {
          label: this.$t('medical.health.q7'),
          show_details: null,
          field: 'asthma',
        },
        {
          label: this.$t('medical.health.q8'),
          show_details: null,
          field: 'lung_diseases',
        },
        {
          label: this.$t('medical.health.q9'),
          show_details: null,
          field: 'frequent_dizziness',
        },
        {
          label: this.$t('medical.health.q12'),
          show_details: null,
          field: 'back_injuries',
        },
        {
          label: this.$t('medical.health.q13'),
          show_details: null,
          field: 'joint_tendon_injuries',
        },
        {
          label: this.$t('medical.health.q14'),
          show_details: null,
          field: 'acute_allergies',
        },
        {
          label: this.$t('medical.health.q15'),
          show_details: null,
          field: 'bronchitis',
        },
        {
          label: this.$t('medical.health.q16'),
          show_details: null,
          field: 'dypsnea_wheezing',
        },
        {
          label: this.$t('medical.health.q17'),
          show_details: null,
          field: 'digestive_disorders',
        },
        {
          label: this.$t('medical.health.q18'),
          show_details: null,
          field: 'strokes',
        },
        {
          label: this.$t('medical.health.q19'),
          show_details: null,
          field: 'head_injuries',
        },
        {
          label: this.$t('medical.health.q20'),
          show_details: null,
          field: 'surgeries',
        },
        {
          label: this.$t('medical.health.q21'),
          show_details: null,
          field: 'blood_diseases',
        },
        {
          label: this.$t('medical.health.q22'),
          show_details: null,
          field: 'endocrine_system_disorders',
        },
        {
          label: this.$t('medical.health.q23'),
          show_details: null,
          field: 'infectious_diseases',
        },
        {
          label: this.$t('medical.health.q24'),
          show_details: null,
          field: 'hospitalization',
        },
        {
          label: this.$t('medical.health.q25'),
          show_details: null,
          field: 'disabilities',
        },
        {
          label: this.$t('medical.health.q26'),
          show_details: null,
          field: 'eye_disorders_diseases',
        },
        {
          label: this.$t('medical.health.q27'),
          show_details: null,
          field: 'other_conditions',
        },
      ],
      smoking_options: [],
      diabetes_options: [],
      languages_options: [],
      countries_options: [],
      food_options: [],
      genders_options: [],
      allergies_switcher: false,
      // preferences_switcher: false
    }
  },
  validations() {
    const form =  {
      confirmation: { checked: value => value === true },
      first_name: { required },
      last_name: { required },
      anti_malaria_pills: { required },
      dob: {
        required,
        minValue(val) {
          return val ? new Date(val) >= new Date(new Date('1930-01-01').setHours(0, 0, 0, 0)) : true
        },
        maxValue(val) {
          return new Date(val) < new Date()
        },
      },
      gender: { required },
      citizenship: { required },
      // residence: { required },
      passport: { required },
      passport_expiration_date: {
        required,
        minValue(val) {
          return val ? new Date(val) >= new Date(new Date().setHours(0, 0, 0, 0)) : true
        },
      },
      email: { required, email },
      phone: { required },
      emergency_contact_fullname: { required },
      emergency_contact_phone: { required },
      food_preference: { required },
      // climbing_insurance_company: { required },
      // climbing_insurance_number: { required },
      // climbing_insurance_emergency_phone: { required },
      asses_general_health: { required },
      asses_stamina: { required },
      // climbing_insurance_coverage_start: {
      //   required,
      //   maxValue(val) {
      //     return new Date(val) < new Date(this.form.climbing_insurance_coverage_end)
      //   },
      // },
      // climbing_insurance_coverage_end: {
      //   required,
      //   minValue(val) {
      //     return val ? new Date(val) >= new Date(new Date().setHours(0, 0, 0, 0)) : true
      //   },
      // },
      health: {
        acute_allergies: {
          required: requiredIf(function() {
            return this.health_fields.find(item => item.field === 'acute_allergies').show_details
          })
        },
        asthma: {
          required: requiredIf(function() {
            return this.health_fields.find(item => item.field === 'asthma').show_details
          })
        },
        back_injuries: {
          required: requiredIf(function() {
            return this.health_fields.find(item => item.field === 'back_injuries').show_details
          })
        },
        blood_diseases: {
          required: requiredIf(function() {
            return this.health_fields.find(item => item.field === 'blood_diseases').show_details
          })
        },
        bronchitis: {
          required: requiredIf(function() {
            return this.health_fields.find(item => item.field === 'bronchitis').show_details
          })
        },
        digestive_disorders: {
          required: requiredIf(function() {
            return this.health_fields.find(item => item.field === 'digestive_disorders').show_details
          })
        },
        disabilities: {
          required: requiredIf(function() {
            return this.health_fields.find(item => item.field === 'disabilities').show_details
          })
        },
        dypsnea_wheezing: {
          required: requiredIf(function() {
            return this.health_fields.find(item => item.field === 'dypsnea_wheezing').show_details
          })
        },
        endocrine_system_disorders: {
          required: requiredIf(function() {
            return this.health_fields.find(item => item.field === 'endocrine_system_disorders').show_details
          })
        },
        epileptic_seizure: {
          required: requiredIf(function() {
            return this.health_fields.find(item => item.field === 'epileptic_seizure').show_details
          })
        },
        eye_disorders_diseases: {
          required: requiredIf(function() {
            return this.health_fields.find(item => item.field === 'eye_disorders_diseases').show_details
          })
        },
        fainting: {
          required: requiredIf(function() {
            return this.health_fields.find(item => item.field === 'fainting').show_details
          })
        },
        frequent_dizziness: {
          required: requiredIf(function() {
            return this.health_fields.find(item => item.field === 'frequent_dizziness').show_details
          })
        },
        head_injuries: {
          required: requiredIf(function() {
            return this.health_fields.find(item => item.field === 'head_injuries').show_details
          })
        },
        heart_diseases: {
          required: requiredIf(function() {
            return this.health_fields.find(item => item.field === 'heart_diseases').show_details
          })
        },
        high_blood_pressure: {
          required: requiredIf(function() {
            return this.health_fields.find(item => item.field === 'high_blood_pressure').show_details
          })
        },
        hospitalization: {
          required: requiredIf(function() {
            return this.health_fields.find(item => item.field === 'hospitalization').show_details
          })
        },
        infectious_diseases: {
          required: requiredIf(function() {
            return this.health_fields.find(item => item.field === 'infectious_diseases').show_details
          })
        },
        joint_tendon_injuries: {
          required: requiredIf(function() {
            return this.health_fields.find(item => item.field === 'joint_tendon_injuries').show_details
          })
        },
        lung_diseases: {
          required: requiredIf(function() {
            return this.health_fields.find(item => item.field === 'lung_diseases').show_details
          })
        },
        mental_conditions: {
          required: requiredIf(function() {
            return this.health_fields.find(item => item.field === 'mental_conditions').show_details
          })
        },
        other_conditions: {
          required: requiredIf(function() {
            return this.health_fields.find(item => item.field === 'other_conditions').show_details
          })
        },
        strokes: {
          required: requiredIf(function() {
            return this.health_fields.find(item => item.field === 'strokes').show_details
          })
        },
        surgeries: {
          required: requiredIf(function() {
            return this.health_fields.find(item => item.field === 'surgeries').show_details
          })
        },
      },
      height: {},
      weight: {},
      height_inch: {},
      health_smoking: {
        required: requiredIf(function() {
          return this.show_details_smoking
        })
      },
      health_diabetes: {
        required: requiredIf(function() {
          return this.show_details_diabetes
        })
      },
      allergic_medications: {
        required: requiredIf(function() {
          return this.show_details_allergic
        })
      },
      prescription_medications: {
        $each: {
          name: { required },
          dose: { required },
          frequency: { required },
          purpose: { required },
        }
      }
    }
    const health_fields = {
      $each: {
        show_details: { required },
      }
    }

    if (this.not_metric) {
      form.weight = {
        required,
        between: between(45, 330),
        integer
      }
      form.height = {
        required,
        between: between(3, 8),
        integer
      }
      form.height_inch = {
        required,
        between: between(0, 12),
        integer
      }
    } else {
      form.height = {
        required,
        between: between(120, 230),
        integer
      }
      form.weight = {
        required,
        between: between(20, 150),
        integer
      }
    }

    return {
      form,
      health_fields,
      show_details_smoking: { required },
      show_details_diabetes: { required },
      show_details_allergic: { required },
    }
  },
  created() {
    getMedicalForm(this.$route.params.hash)
      .then(response => {
        this.form = response.data
        if (this.form.confirmation) {
          this.show_details_smoking = !!this.form.health_smoking
          this.show_details_diabetes = !!this.form.health_diabetes
          this.show_details_allergic = !!this.form.allergic_medications
          for (const field in this.form.health) {
            this.health_fields.find(item => item.field === field).show_details = !!this.form.health[field]
          }
        }
        if (!this.form.prescription_medications) this.form.prescription_medications = []
        if (this.form.height_inch || this.form.height_inch === 0) this.not_metric = true
        if (this.form.food_allergy) this.allergies_switcher = true
        // if (this.form.food_preferences) this.preferences_switcher = true
      })
    getGenders().then(res => { this.genders_options = res.data })
    getCountries().then(res => { this.countries_options = res.data })
    getSmokingFrequencies().then(response => { this.smoking_options = response.data })
    getFoodPreferences().then(response => { this.food_options = response.data })
    getDiabetesTypes().then(response => { this.diabetes_options = response.data })
  },
  computed: {
    getCurrentLang() {
      return localStorage.getItem('content_language') === 'ru' ? 'ru' : 'en'
    },
    bmi() {
      let height = null
      let weight = null
      if (!this.not_metric) {
        if (this.form.height && this.form.weight) {
          height = this.form.height
          weight = this.form.weight
          return (weight / ((height / 100) ** 2)).toFixed(1)
        }
      } else {
        if (this.form.height && this.form.weight && this.form.height_inch >= 0) {
          weight = this.form.weight
          height = this.form.height_inch + (this.form.height * 12)
          return ((weight / (height ** 2) * 703)).toFixed(1)
        }
      }
      return ''
    },
    contentLanguage() {
      return localStorage.getItem('content_language')
    },
    branding() {
      return process.env.VUE_APP_IS_BRANDING
    },
  },
  watch: {
    allergies_switcher(val) {
      if (!val) {
        this.form.food_allergy = null
      }
    },
    // preferences_switcher(val) {
    //   if (!val) {
    //     this.form.food_preferences = null
    //   }
    // },
  },
  methods: {
    onChangeRUToUS(value, prop) {
      let answer = ''
      const converter = {
        'а': 'a',
        'б': 'b',
        'в': 'v',
        'г': 'g',
        'д': 'd',
        'е': 'e',
        'ё': 'e',
        'ж': 'zh',
        'з': 'z',
        'и': 'i',
        'й': 'y',
        'к': 'k',
        'л': 'l',
        'м': 'm',
        'н': 'n',
        'о': 'o',
        'п': 'p',
        'р': 'r',
        'с': 's',
        'т': 't',
        'у': 'u',
        'ф': 'f',
        'х': 'h',
        'ц': 'c',
        'ч': 'ch',
        'ш': 'sh',
        'щ': 'sch',
        'ь': '',
        'ы': 'y',
        'ъ': '',
        'э': 'e',
        'ю': 'yu',
        'я': 'ya',
        'А': 'A',
        'Б': 'B',
        'В': 'V',
        'Г': 'G',
        'Д': 'D',
        'Е': 'E',
        'Ё': 'E',
        'Ж': 'Zh',
        'З': 'Z',
        'И': 'I',
        'Й': 'Y',
        'К': 'K',
        'Л': 'L',
        'М': 'M',
        'Н': 'N',
        'О': 'O',
        'П': 'P',
        'Р': 'R',
        'С': 'S',
        'Т': 'T',
        'У': 'U',
        'Ф': 'F',
        'Х': 'H',
        'Ц': 'C',
        'Ч': 'Ch',
        'Ш': 'Sh',
        'Щ': 'Sch',
        'Ь': '',
        'Ы': 'Y',
        'Ъ': '',
        'Э': 'E',
        'Ю': 'Yu',
        'Я': 'Ya'
      }

      for (let i = 0; i < value.length; ++i) {
        if (converter[value[i]] === undefined) {
          answer += value[i]
        } else {
          answer += converter[value[i]]
        }
      }
      this.form[prop] = answer
    },
    onShowModal() {
      this.$modal.show('confirm-modal')
    },
    onHideModal() {
      this.$modal.hide('confirm-modal')
    },
    onRouteHome() {
      this.$router.push({name: 'home', params: {hash: this.$route.params.hash}})
    },
    onSwitchMetric() {
      this.form.weight = null
      this.form.height = null
      this.form.height_inch = null
    },
    onCheckForm() {
      this.$v.$touch()
      if (this.$v.$invalid) {
        this.$notify({
          type: 'error',
          title: this.$t('notify.check'),
        })
        this.$nextTick(() => {
          const errorElem = document.querySelector('.form-group__error div')
          const errorElemSecond = document.querySelector('.medical__question-error')
          const errorElemThird = document.querySelector('.medical__borders-error')
          if (errorElem) {
            errorElem.scrollIntoView({block: 'center', behavior: 'smooth'})
            return
          }
          if (errorElemSecond) {
            errorElemSecond.scrollIntoView({block: 'center', behavior: 'smooth'})
            return
          }
          if (errorElemThird) errorElemThird.scrollIntoView({block: 'center', behavior: 'smooth'})
        })
      } else {
        if (this.form.crop.coords && !this.form.crop.photo) {
          this.$refs.submit.preload = true
          this.$refs.cropper.sendCrop()
            .then(() => { this.sendForm() })
            .catch(() => { this.$refs.submit.preload = false })
        } else {
          this.sendForm()
        }
      }
    },
    sendForm() {
      this.$refs.submit.preload = true
      patchMedicalForm(this.$route.params.hash, this.normalizeForm())
        .then(() => {
          this.$notify({
            type: 'success',
            title: 'Form changed',
          })
          this.$router.push({name: 'home', params: {hash: this.$route.params.hash}})
        })
        .catch(() => { this.$refs.submit.preload = false })
    },
    normalizeForm() {
      const form = {...this.form}
      if (!this.show_details_smoking) {
        form.health_smoking = null
      } else {
        form.health_smoking = form.health_smoking.id
      }
      if (!this.show_details_diabetes) {
        form.health_diabetes = null
      } else {
        form.health_diabetes = form.health_diabetes.id
      }
      if (!this.show_details_allergic) {
        form.allergic_medications = null
      }
      form.food_preference = form.food_preference.id
      form.citizenship = form.citizenship.code
      if (form.residence && typeof form.residence !== 'string') {
        form.residence = form.residence.code
      }
      this.health_fields.forEach(item => {
        if (!item.show_details) form.health[item.field] = null
      })
      if (this.form.prescription_medications && !this.form.prescription_medications.length) this.form.prescription_medications = null
      delete form.crop.crop_url
      delete form.crop.photo_url
      delete form.first_name
      delete form.last_name
      return form
    },
    onCloneItem() {
      this.form.prescription_medications.push({
        name: '',
        dose: '',
        frequency: '',
        purpose: '',
      })
      this.$v.$touch()
    },
    onRemoveItem(index) {
      this.form.prescription_medications.splice(index, 1)
      this.$v.$touch()
    },
  },
}
</script>

<style scoped lang="sass">
.medical__section
  margin-bottom: 20px
  padding: 30px
  border: 1px solid #e3e3e3
  border-radius: 10px

  +max-w($mobile_lg)
    padding: 15px 10px

  p
    margin-bottom: 10px
    font-size: 13px
    line-height: 1.6

    &:last-child
      margin-bottom: 0

    &:empty
      display: none

    a
      display: inline-flex
      flex-direction: column
      width: max-content
      color: $color-text-base
      transition: color 0.3s

      &:after
        content: ''
        display: block
        height: 2px
        border-radius: 3px
        background-color: #aae182
        transform: translateY(-1px)
        transition: opacity 0.3s

      &:active
        &:after
          opacity: 0

      @media (hover: hover)
        &:hover,
        &:focus-visible
          &:after
            opacity: 0

  ul
    margin-bottom: 10px

    li
      position: relative
      margin-bottom: 5px
      padding-left: 14px
      font-size: 13px
      line-height: 1.6

      &:empty
        display: none

      &.no-indent
        margin-bottom: 0

      &:before
        content: ""
        position: absolute
        top: 8px
        left: 0
        width: 4px
        height: 4px
        border-radius: 50%
        background-color: $color-text-base

      &:last-child
        margin-bottom: 0

    &:last-child
      margin-bottom: 0

  b
    display: inline-block
    margin-bottom: 5px
    font-weight: 500

.medical__section--indent
  margin-bottom: 50px

  +max-w($mobile_lg)
    margin-bottom: 30px

.medical__spoiler
  max-height: 0
  overflow: hidden
  transition: max-height 0.5s

.medical__spoiler--show
  max-height: 1150px

.medical__title
  margin-bottom: 20px

.medical__subt
  display: flex
  align-items: center
  gap: 10px
  margin-bottom: 20px
  font-weight: 500
  text-transform: uppercase

.medical__spoiler-control
  display: flex
  align-items: center
  gap: 10px
  padding: 8px 20px 8px 8px
  border-radius: 80px
  color: #fff
  background-color: $color-theme
  font-size: 13px
  font-weight: 600
  transition: background-color 0.3s

  img
    transition: transform 0.3s

  &:active
    background-color: #72a915

  @media (hover: hover)
    &:hover,
    &:focus-visible
      background-color: #72a915

.medical__spoiler-control--show
  img
    transform: rotate(180deg)

.medical__holder
  margin-top: 20px
  display: flex
  justify-content: center

.medical__questions
  margin-bottom: 50px

.medical__question
  display: grid
  grid-template-columns: 215px 110px 1fr
  align-items: center
  gap: 30px
  margin-bottom: 12px

  &:last-child
    margin-bottom: 0

  span
    font-size: 13px
    font-weight: 500

  +max-w($mobile_lg)
    grid-template-columns: 1fr 110px
    gap: 15px

.medical__radios
  display: flex
  gap: 10px

.medical__radios--indent
  margin-bottom: 30px

.medical__radio
  position: relative
  width: 50px
  padding: 6px 0
  border-radius: 60px
  color: #fff
  font-weight: 500
  font-size: 13px
  letter-spacing: -0.02em
  text-align: center
  transition: background-color 0.3s
  cursor: pointer
  overflow: hidden

  input
    +visually-hidden

    &:checked + .medical__radio-bg
      background-color: $color-theme

.medical__radio-bg
  position: absolute
  left: 0
  top: 0
  z-index: -1
  width: 100%
  height: 100%
  background-color: #5b5b5b
  transition: background-color 0.3s

.medical__paragraph
  margin-bottom: 20px
  font-size: 13px

  &:last-child
    margin-bottom: 0

.medical__details
  opacity: 0
  transition: opacity 0.3s, max-height 0.3s

  +max-w($mobile_lg)
    grid-column: 1 / 3
    max-height: 0

.medical__details--show
  opacity: 1
  max-height: 42px

.medical__borders
  display: flex
  gap: 140px
  margin-bottom: 40px
  padding: 25px 0
  border-top: 1px solid #e3e3e3
  border-bottom: 1px solid #e3e3e3

  +max-w($laptop)
    gap: 30px 0
    justify-content: space-between

  +max-w($mobile_lg)
    flex-direction: column

.medical__range
  width: 350px
  display: flex
  justify-content: space-between

  +max-w($mobile_xs)
    width: 290px

.medical__range-label
  flex: 1 1
  position: relative
  padding-bottom: 20px
  text-align: center
  cursor: pointer

  input
    +visually-hidden

    &:checked
      & ~ .medical__range-point
        background-color: $color-theme
        transform: translateX(-50%) scale(1.4)

      & ~ .medical__range-name
        color: #404040

      & ~ .medical__range-line
        background-color: $color-theme

.medical__range-label--prev

  .medical__range-line
    background-color: $color-theme

  .medical__range-point
    background-color: $color-theme

.medical__range-line
  position: absolute
  bottom: 3.5px
  right: 50%
  width: 85px
  height: 2px
  background-color: #c4c4c4
  transition: background-color 0.3s

  +max-w($mobile_xs)
    width: 70px

.medical__range-point
  position: absolute
  left: 50%
  bottom: 0
  z-index: 1
  width: 10px
  height: 10px
  border: 2px solid #fff
  border-radius: 50%
  background-color: #c4c4c4
  transform: translateX(-50%)
  transition: background-color 0.3s, transform 0.3s

.medical__range-name
  color: #959595
  font-weight: 500
  transition: color 0.3s

.medical__medications
  margin-bottom: 40px
  padding-bottom: 30px
  border-bottom: 1px solid #e3e3e3

.medical__medications--no-indent
  padding-bottom: 0

.medical__medication
  position: relative
  display: grid
  grid-template-columns: 230px 120px 130px 1fr 26px
  gap: 10px
  margin-bottom: 20px

  +max-w($mobile_lg)
    grid-template-columns: 1fr

  .form-group:last-of-type
    width: calc(100% - 35px)

.medical__medication-delete
  position: absolute
  right: 0
  top: 31px
  display: flex
  align-items: center
  justify-content: center
  width: 26px
  height: 26px
  border: 1px solid rgba(221, 221, 221, 0.5)
  border-radius: 50%
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1)
  background-color: #fff
  transition: background-color 0.3s

  &:active
    background-color: rgba(0, 0, 0, 0.1)

  @media (hover: hover)
    &:hover,
    &:focus-visible
      background-color: rgba(0, 0, 0, 0.1)

  +max-w($mobile_lg)
    top: unset
    bottom: 6px

label.medical__info
  cursor: pointer

.medical__info
  display: flex
  align-items: flex-start
  gap: 15px
  margin-bottom: 30px
  padding: 10px
  border: 1px solid #ffa51f
  transition: border-color 0.3s

  p, span
    color: #404040
    font-size: 12px
    line-height: 18px

.medical__info--error
  border-color: $color-error

.medical__row
  display: grid
  gap: 0 20px

.medical__row--mix-1
  grid-template-columns: 200px 200px 154px 200px

  +max-w($laptop)
    grid-template-columns: repeat(2, 1fr)

  +max-w($mobile_sm)
    grid-template-columns: 1fr

.medical__row--mix-2
  grid-template-columns: 200px 200px 163px 154px

  +max-w($laptop)
    grid-template-columns: repeat(2, 1fr)

  +max-w($mobile_sm)
    grid-template-columns: 1fr

.medical__row--mix-3
  grid-template-columns: 200px 82px 82px 82px 82px

  +max-w($mobile_lg)
    grid-template-columns: 1fr

.medical__row--mix-4
  grid-template-columns: 200px 200px 135px 135px 200px

  +max-w(991)
    grid-template-columns: 1fr 1fr

  +max-w($mobile_sm)
    grid-template-columns: 1fr

.medical__row--mix-5
  grid-template-columns: repeat(auto-fit, 105px)

.medical__row--mix-6
  grid-template-columns: 1fr 1fr

  +max-w($mobile_lg)
    grid-template-columns: 1fr

.medical__row--diet
  grid-template-columns: 200px 1fr 1fr
  align-items: flex-end

  +max-w($mobile_xl)
    grid-template-columns: 1fr

.medical__diet-switchers
  display: grid
  grid-template-columns: repeat(3, 1fr)
  align-items: flex-end
  gap: 0 20px

.medical__row--type-200
  grid-template-columns: 201px 200px 200px

  +max-w($mobile_lg)
    grid-template-columns: 1fr

.medical__part
  margin-bottom: 30px

  +max-w($mobile_lg)
    margin-bottom: 10px

    &:last-of-type
      margin-bottom: 30px

.medical__note
  font-size: 13px
  font-weight: 500

  span
    font-weight: 400
    color: #5f5f5f

.medical__line
  display: flex
  align-items: flex-start
  flex-wrap: wrap
  gap: 10px 20px

  +max-w($mobile_lg)
    gap: 10px

  +max-w($mobile_sm)
    gap: 0 20px
    align-items: center

  .form-group
    width: 105px

    +max-w($mobile_sm)
      width: 100%

  .form-group--center-switch
    +max-w($mobile_sm)
      order: -2
      width: 100px

.medical__bmis
  display: flex
  align-items: flex-start
  gap: 10px 20px

  +max-w($mobile_lg)
    order: -1
    width: 100%
    margin-bottom: 10px

  +max-w($mobile_sm)
    width: unset

.medical__bmi
  padding-top: 27px

  +max-w($mobile_lg)
    padding-top: 0

  span
    display: block
    font-size: 13px

.medical__question-block
  span
    display: block

.medical__question-block--flex
  display: flex
  align-items: flex-start
  gap: 10px

span.medical__question-error
  color: $color-error
  font-size: 12px

.medical__vaccinated
  display: grid
  grid-template-columns: 230px 154px
  align-items: flex-start
  margin: 30px 0
  gap: 20px

  +max-w($mobile_sm)
    grid-template-columns: 1fr

.medical__footer
  display: flex
  justify-content: flex-end
  gap: 20px

  +max-w($mobile_sm)
    flex-direction: column

.medical__error
  margin: -25px 0 11px
  color: $color-error
  font-size: 12px

.medical__borders-item
  position: relative

.medical__borders-error
  position: absolute
  left: 0
  bottom: -17px
  color: $color-error
  font-size: 12px
</style>
